import React, { FC, ReactElement, ReactNode, createContext } from 'react';

import { IRootStore } from 'store/RootStore';

export const StoreContext = createContext<IRootStore>({} as IRootStore);

export type StoreComponent = FC<{
  store: IRootStore;
  children: ReactNode;
}>;

export const StoreProvider: StoreComponent = ({ children, store }): ReactElement => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
