import pluralize from 'ati-utils/pluralize';
import { makeAutoObservable } from 'mobx';

import { TI18n } from 'localization/types';
import { toMoneyFormat } from 'utils/string/format';

import { RootStore } from '../RootStore';

export type TDeclinableSubject = keyof TI18n['app']['declensions'];

class ViewStore {
  root;

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this);
  }

  get i18n() {
    return this.root.app.i18n;
  }

  atisCount = (value?: number | null) => {
    if (!value) return '';

    return (
      toMoneyFormat(value, this.root.app.locale) +
      ' ' +
      pluralize({
        ...this.i18n.app.declensions.atis,
        value,
      })
    );
  };

  pluralizedCount = (value: number | null, declinableSubject: TDeclinableSubject, limit?: number) => {
    if (value === null) return '';

    let count: number | string = value;
    if (limit && value > limit) {
      count = `${limit}+`;
    }

    return `${count} ${pluralize({
      ...this.i18n.app.declensions[declinableSubject],
      value,
    })}`;
  };
}

export { ViewStore };
