import { action, computed, makeObservable, observable } from 'mobx';
import { ChangeEvent } from 'react';

export interface IField {
  value?: string;
  default?: string;
}

abstract class Field {
  value;
  default;
  required = false;

  constructor(data: IField = {}) {
    this.default = '';
    this.value = this.default;

    makeObservable(this, {
      value: observable,
      default: observable,
      required: observable,
      isDefault: computed,
      setValueFromInput: action,
    });

    this.default = data.default || '';
    this.value = data.value || this.default;
  }

  get isDefault() {
    return this.value === this.default;
  }

  setValueFromInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const isZeroValueAndBlur = !event;
    if (isZeroValueAndBlur) {
      this.setValue('');
      return;
    }

    this.setValue(event.target.value);
  };

  reset = () => {
    this.value = this.default;
  };

  abstract setValue(value: any): void;
}

export { Field };
