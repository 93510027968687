import { makeAutoObservable } from 'mobx';

import { RootStore } from 'store/RootStore';

export class CargoType {
  root: RootStore;
  cargoTypes: Array<string>;

  constructor(root: RootStore) {
    this.root = root;
    this.cargoTypes = [];

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.cargoTypes.length === 0;
  }

  setCargoTypes = (cargoTypes: string[]) => {
    this.cargoTypes = cargoTypes;
  };

  clearCargoTypes = () => {
    this.cargoTypes = [];
  };

  get requestData() {
    if (this.isDefault) return null;

    return this.cargoTypes;
  }
}
