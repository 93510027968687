import { makeAutoObservable } from 'mobx';

import { TFirmContact, fetchContact } from 'api/contact';
import { PayedSerivces } from 'constants/profile';
import { snakeToCamelObj } from 'utils/objects';

import { RootStore } from '../RootStore';

export type UserData = {
  isPaidUser: boolean;
  isUser: boolean;
  profile?: Profile;
};

type Profile = {
  atiCode: string;
  countryId: number;
  contact: {
    name: string;
    id: number;
    balance: number;
    // Есть еще, но пока не требуется
  } & TFirmContact;
  payedServices?: {
    services: Array<TService>;
  };
  passedOnlyFastRegistration: boolean;
};

type TService = {
  serviceAmount: null;
  serviceId: PayedSerivces;
  serviceName: string;
};

class ProfileStore {
  root;
  data: UserData | null;

  constructor(root: RootStore) {
    this.root = root;
    this.data = null;
    makeAutoObservable(this);

    this.init();
  }

  get atiCode() {
    return this.data?.profile?.atiCode;
  }

  get contact() {
    return this.data?.profile?.contact;
  }

  get isFastReg() {
    return this.data?.profile?.passedOnlyFastRegistration;
  }

  get hasUnlimitedNotificationsLicense() {
    return this.data?.profile?.payedServices?.services.some(
      service =>
        service.serviceId === PayedSerivces.UnlimititedSubscriptionNotifications ||
        service.serviceId === PayedSerivces.AllInclusive ||
        service.serviceId === PayedSerivces.Carrier,
    );
  }

  get hasWithoutAdsLicence() {
    return this.data?.profile?.payedServices?.services.find(service => service.serviceId === PayedSerivces.WithoutAds);
  }

  init = () => {
    if (typeof window === 'undefined') return;

    this.data = snakeToCamelObj(window.atiUser);
  };

  fetchContactData = async () => {
    const contact = await fetchContact();

    if (this.data?.profile) {
      this.data.profile.contact = { ...this.data?.profile?.contact, ...contact };
    }

    return this.data?.profile?.contact;
  };
}

export { ProfileStore };
