import { makeAutoObservable, reaction } from 'mobx';

import { currenciesModifiers, currenciesRateTypes } from 'constants/payment';
import { RootStore } from 'store/RootStore';
import { FloatField } from 'store/fileds/FloatField';
import { SelectField } from 'store/fileds/SelectField';

class Rate {
  root;

  currency;
  totalRate;
  totalRateCurrency;
  ratePerKm;

  constructor(root: RootStore) {
    this.root = root;
    this.currency = new SelectField(this.root.options.getPureCurrencies, this.countryCurrencyType);
    this.totalRate = new FloatField();
    this.totalRateCurrency = new SelectField(this.getCurrencyModifiers);
    this.ratePerKm = new FloatField();

    makeAutoObservable(this);

    reaction(
      () => this.root.dictionaries.data.currencyCountry.length + this.root.dictionaries.data.currencyTypes.length,
      () => {
        if (this.root.app.isDataSetting) return;

        if (!(this.root.dictionaries.data.currencyCountry.length && this.root.dictionaries.data.currencyTypes.length))
          return;

        if (this.countryCurrencyType && this.currency.isDefault) {
          if (this.currency.isDefault) {
            this.currency.setOption(this.countryCurrencyType);
            this.currency.setDefaultOption(this.countryCurrencyType);

            return;
          }
        }
      },
    );
  }

  get isRateInThousands() {
    return this.totalRateCurrency.option?.modifier === currenciesModifiers.thousands;
  }

  getCurrencyByRateType = (baseCurrencyId: number, rateType: TCurrencyRateTypes) =>
    this.root.options
      .getRateTypes()
      .find(currency => currency.rateType === rateType && currency.currencyId === baseCurrencyId);

  setCurrencyModifier = (modifier: string) => {
    const [currencyModifier] = this.getCurrencyModifiers().filter(currency => currency.modifier === modifier);

    if (currencyModifier) {
      this.totalRateCurrency.setOptionByValue(currencyModifier.id);

      this.totalRate.data && this.totalRate.setValue(this.totalRate.data / parseInt(modifier));
    }
  };

  getCurrencyModifiers = () =>
    this.root.options
      .getRateTypes()
      .filter(
        currency =>
          currency.currencyId === this.currency.option.id && currency.id !== this.currency.option.currencyIdPerKm,
      );

  get ratePerKmCurrency() {
    return this.root.options
      .getRateTypes()
      .filter(currency => currency.id === this.currency.option.currencyIdPerKm)?.[0];
  }

  get hasCurrencyPerHourSelected() {
    return this.totalRateCurrency.option?.rateType === currenciesRateTypes.perHour;
  }

  get isDefault() {
    return (
      this.currency.isDefault &&
      this.totalRate.isDefault &&
      this.totalRateCurrency.isDefault &&
      this.ratePerKm.isDefault
    );
  }

  get requestData() {
    if ((!this.totalRate.data && !this.ratePerKm.data) || (this.hasCurrencyPerHourSelected && !this.totalRate.data))
      return null;

    if (this.hasCurrencyPerHourSelected && this.totalRate.data) {
      return {
        currencyId: this.currency.data,
        ratePerHour: this.totalRate.data,
      };
    }

    return {
      currencyId: this.currency.data,
      ...(this.totalRate.data && {
        rateTotal: this.totalRate.data * parseInt(this.totalRateCurrency.option.modifier),
      }),
      ...(this.ratePerKm.data && {
        ratePerKm: this.ratePerKm.data,
      }),
    };
  }

  get countryCurrencyPerKmType() {
    const {
      profile,
      dictionaries: {
        data: { currencyCountry },
      },
    } = this.root;
    const currencyTypes = this.root.options.getRateTypes();

    const currencyId = currencyCountry.find(item => parseInt(item.countryId) === profile.data?.profile?.countryId)?.id;

    const currencyType = currencyTypes.find(currency => currency.id.toString() === currencyId);

    const currencyTypePerKm = currencyTypes.find(currency => currency.id === currencyType?.currencyIdPerKm);

    return currencyTypePerKm;
  }

  get countryCurrencyType() {
    const {
      profile,
      dictionaries: {
        data: { currencyCountry },
      },
    } = this.root;
    const currencyTypes = this.root.options.getRateTypes();
    const currencyId = currencyCountry.find(item => parseInt(item.countryId) === profile.data?.profile?.countryId)?.id;
    const currencyType = currencyTypes.find(currency => currency.id.toString() === currencyId);

    return currencyType;
  }

  reset = () => {
    this.currency.reset();
    this.totalRate.reset();
    this.totalRateCurrency.reset();
    this.ratePerKm.reset();
  };
}

export { Rate };
