import type { SnakeCasedPropertiesDeep } from 'type-fest';

import type { SearchRequestType, SearchResultType } from 'api/search/types';
import { PopularDestinationsRequest } from 'types/cargosApp/Requests';

import axios from '../axios';

function getLoads(data: SnakeCasedPropertiesDeep<SearchRequestType>) {
  return axios.post('/webapi/public/v1.0/loads/search', data);
}

function getLoadsByIds(ids: string[]) {
  return axios.post<SearchResultType>('webapi/v1.0/loads/search-by-ids/old', ids);
}

function getPopularDestinations(data: PopularDestinationsRequest) {
  return axios.post('/webapi/public/v1.0/loads/seo/popular-destinations', data || {});
}

function hideLoads(loads: string[]) {
  return axios.post('/webapi/loads/search/hidden_loads', loads);
}

function showLoads() {
  const data = {
    delete_firms: true,
    delete_loads: true,
  };
  return axios.delete('/webapi/loads/search/hidden', { data });
}

export { getLoads, getLoadsByIds, getPopularDestinations, hideLoads, showLoads };
