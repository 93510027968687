import { action, makeObservable } from 'mobx';

import { Field, IField } from './Field';

class IntegerField extends Field {
  onlyNumberRegExp = /^\d+$/;
  constructor(data: IField = {}) {
    super(data);
    makeObservable(this, {
      setValue: action,
    });
  }

  get view() {
    return this.value?.toString();
  }

  get data() {
    if (!this.value || !this.value.trim()) return null;

    return parseInt(this.value, 10);
  }

  setValue = (value: number | string) => {
    const normalizedValue = value.toString().trim();

    if (normalizedValue === '0') return;

    this.value = normalizedValue;
  };
}

export { IntegerField };
