function NoneImg() {
  return (
    <svg fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M21.0329 19.061L15.9718 14L21.0329 8.93896L19.061 6.96713L14 12.0282L8.93897 6.96713L6.96713 8.93896L12.0282 14L6.96713 19.061L8.93897 21.0329L14 15.9718L19.061 21.0329L21.0329 19.061ZM14 0C17.856 0 21.1533 1.36931 23.892 4.10798C26.6307 6.84665 28 10.144 28 14C28 17.856 26.6307 21.1533 23.892 23.892C21.1533 26.6307 17.856 28 14 28C10.144 28 6.84665 26.6307 4.10798 23.892C1.36931 21.1533 0 17.856 0 14C0 10.144 1.36931 6.84665 4.10798 4.10798C6.84665 1.36931 10.144 0 14 0Z"
        fill="var(--glz-color-neutral-tone-4)"
        fillRule="evenodd"
      />
    </svg>
  );
}

export { NoneImg };
