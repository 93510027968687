import { makeAutoObservable } from 'mobx';

enum PopupIds {
  filterPopup = 'filterPopup',
  geoListsPopup = 'geoListsPopup',
  onlyPaidPopup = 'onlyPaidPopup',
  firmListsPopup = 'firmListsPopup',
  extendedFirmSearchPopup = 'extendedFirmSearchPopup',
  editContactPopup = 'editContactPopup',
  filterDeleteConfirmationPopup = 'filterDeleteConfirmationPopup',
  filterUnsubscribeConfirmationPopup = 'filterUnsubscribeConfirmationPopup',
}

type Popup = {
  payload: any;
  isOpened: boolean;
} | null;

class Popups {
  filterPopup: Popup;
  geoListsPopup: Popup;
  onlyPaidPopup: Popup;
  firmListsPopup: Popup;
  extendedFirmSearchPopup: Popup;
  editContactPopup: Popup;
  filterDeleteConfirmationPopup: Popup;
  filterUnsubscribeConfirmationPopup: Popup;

  constructor() {
    this.filterPopup = null;
    this.geoListsPopup = null;
    this.onlyPaidPopup = null;
    this.firmListsPopup = null;
    this.extendedFirmSearchPopup = null;
    this.editContactPopup = null;
    this.filterDeleteConfirmationPopup = null;
    this.filterUnsubscribeConfirmationPopup = null;

    makeAutoObservable(this);
  }

  open = (popupId: PopupIds, payload?: any) => {
    this[popupId] = { payload, isOpened: true };
  };

  close = (popupId: PopupIds) => {
    this[popupId] = null;
  };

  closeAll = () => {
    Object.keys(PopupIds).forEach(popupId => this.close(popupId as PopupIds));
  };
}

export { Popups, PopupIds };
