import { makeAutoObservable, reaction } from 'mobx';

import { BoardType } from 'components/Board/types';
import type { RootStore } from 'store/RootStore';
import { BooleanField } from 'store/fileds/BooleanField';

import { Board } from './Board';

class BoardsStore {
  root: RootStore;
  boards: Array<Board>;
  withAuction: BooleanField;
  commonBoard: Board;
  isAllSelected: boolean;
  isBoardsUnavailable: BooleanField;

  constructor(root: RootStore) {
    this.root = root;
    this.commonBoard = new Board(root, { isCommon: true } as BoardType);
    this.boards = [this.commonBoard, ...this.root.boardsRepo.boards];
    this.isAllSelected = true;
    this.withAuction = new BooleanField();
    this.isBoardsUnavailable = this.root.boardsRepo.isBoardsUnavailable;

    makeAutoObservable(this);

    this.selectAllBoards();

    reaction(
      () => this.selectedBoards,
      () => {
        if (this.isOnlyCommonBoardSelected || this.selectedBoards.length === 0) {
          this.commonBoard.isSelected.setData(true);
          this.commonBoard.isDisabled.setData(true);
        } else {
          this.commonBoard.isDisabled.setData(false);
        }
      },
    );
  }

  get isOnlyCommonBoardSelected() {
    return this.selectedBoards.length === 1 && this.selectedBoards[0].isCommon;
  }

  get selectedBoards() {
    return this.boards.filter(board => board.isSelected.value);
  }

  get isWithAuctionDefault() {
    return this.withAuction.value === this.withAuction.default;
  }

  get isSelectedBoardsDefault() {
    const isAllBoardsSelected =
      this.boards.filter(board => board.isSelected.value !== board.isSelected.default).length === 0;

    return isAllBoardsSelected;
  }

  get isDefault() {
    return this.isSelectedBoardsDefault && this.isWithAuctionDefault;
  }

  setBoards = (boards: Array<Board>) => {
    this.boards = [this.commonBoard, ...boards];
  };

  setIsAllSelected = (value: boolean) => {
    this.isAllSelected = value;
  };

  selectAllBoards = () => {
    this.setIsAllSelected(true);
    this.boards.forEach(board => board.isSelected.setData(true));
  };

  selectOnlyPersonalBoards = () => {
    this.root.filter.boards.selectAllBoards();
    this.root.filter.boards.commonBoard.isSelected.setData(false);
  };

  toggleIsAllSelected = () => {
    if (this.isAllSelected) {
      this.unSelectAllBoards();
    } else {
      this.selectAllBoards();
    }
  };

  unSelectAllBoards = () => {
    this.setIsAllSelected(false);
    this.boards.forEach(board => board.isSelected.setData(false));
  };

  selectBoardById = (id: string) => {
    const board = this.boards.find(board => board.id === id);
    board?.isSelected.setData(true);
  };

  fetchBoards = async () => {
    const boards = await this.root.boardsRepo.fetchBoards();
    this.setBoards(boards);
  };

  get requestData() {
    if (this.isSelectedBoardsDefault) return [];

    return this.selectedBoards.map(board => board.id);
  }
}

export { BoardsStore };
