export type MetricKeys = keyof typeof events;
export type MetricsEvent = typeof events[MetricKeys];

export const events = {
  /* DataLake события */
  searchLoads: 'SearchLoads',
  savedFiltersOpen: 'SavedFiltersOpen',
  favoritesOpen: 'FavouritesOpen',
  historyOpen: 'SearchHistoryOpen',
  byTrucksOpen: 'TruckSearchOpen',

  /*
    ЯМетрика события
    при добавлении/редактировании было бы круто
    обновить документацию в нашем пространстве.
  */

  addTsFromLoads: 'ls-add-ts-from-loads',
  'dark-theme': 'ls-select-dark-theme',
  'default-theme': 'ls-select-default-theme',
  lsAddSubscriptions: 'ls-add-subscriptions',
  loadsChainsClick: 'ls-loads-chains-click',
  lsSubscribeFilterFromDirectAction: 'ls-notification-new',
  lsSubscribeFilterFromDirectActionSuccess: 'ls-notification-new-success',
  selectedWithOffersTab: 'ls-click-with-vstrechka',
  selectedWithAuctionsTab: 'ls-click-with-torgami',
} as const;
