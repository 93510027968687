import { makeAutoObservable } from 'mobx';

import { getPopularDestinations } from 'api/search';
import { TI18n } from 'localization/types';
import { cleanEmpty } from 'utils/objects';

import { RootStore } from '../RootStore';

type PopularDestination = {
  name: string;
  url: string;
};

class PopularDestinations {
  root: RootStore;
  i18n: TI18n;
  data: PopularDestination[];
  default: PopularDestination[];

  constructor(root: RootStore, i18n: TI18n) {
    this.root = root;
    this.i18n = i18n;
    this.data = [];
    this.default = [
      { name: this.i18n.popularDestinations.destinations.inRussia, url: 'https://loads.ati.{{env}}/russia' },
      {
        name: this.i18n.popularDestinations.destinations.inArmenia,
        url: 'https://loads.ati.{{env}}/armeniya',
      },
      {
        name: this.i18n.popularDestinations.destinations.inBelarus,
        url: 'https://loads.ati.{{env}}/belarus',
      },
      {
        name: this.i18n.popularDestinations.destinations.inKazakhstan,
        url: 'https://loads.ati.{{env}}/kazakhstan',
      },
      {
        name: this.i18n.popularDestinations.destinations.inKyrgyzstan,
        url: 'https://loads.ati.{{env}}/kyrgyzstan',
      },
      {
        name: this.i18n.popularDestinations.destinations.inUzbekistan,
        url: 'https://loads.ati.{{env}}/uzbekistan',
      },
    ];

    makeAutoObservable(this);
  }

  fetchData = async () => {
    const requestData = {
      from: this.root.filter.from.requestData,
      to: this.root.filter.to.requestData,
    };

    try {
      const { data } = await getPopularDestinations(cleanEmpty(requestData));

      this.setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  setData = (data: PopularDestination[]) => {
    this.data = data;
  };

  get destinations() {
    if (!this.root.app.searchResult || !this.root.app.popularDestinations.data.length) {
      return this.default;
    }

    return this.data;
  }
}

export { PopularDestinations };
