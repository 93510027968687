import { makeAutoObservable } from 'mobx';

import logger from 'sentry.client.config';
import { RootStore } from 'store/RootStore';

interface Error {
  name: string;
  message: string;
  type?: 'error' | 'warning';
}

interface RetrievableError {
  [key: string]: Error;
}

class ErrorsStore {
  root: RootStore;
  retrievable: RetrievableError;

  constructor(root: RootStore) {
    this.root = root;
    this.retrievable = {};

    makeAutoObservable(this);
  }

  get retrievableMessages(): Error[] {
    return Object.values(this.retrievable);
  }

  showMessage = ({ message, type = 'error' }: Error) => {
    logger?.hub?.show({
      text: message,
      type,
      delay: 5000,
    });
  };

  setRetrievable = ({ name, message, type = 'error' }: Error) => {
    this.retrievable[name] = { name, message, type };

    this.showMessage({ name, message, type });
  };

  deleteCurrentRetrievable = () => {
    if (this.retrievableMessages.length === 0) return;

    const { name } = this.retrievableMessages[0];

    delete this.retrievable[name];
  };
}

export { ErrorsStore };
