import axios from './axios';

export type TSiteFeature = { [featureId: number]: boolean };

export type TServerProp = {
  Name: string;
  StringValue: string | null;
  IntValue: number | null;
  FloatValue: number | null;
};

const getSiteFeatures = (ids: number[]) => {
  const query = ids.map(id => `id=${id}`).join('&');
  return axios.get<{ [featureId: number]: boolean }>(`/webapi/configuration/siteFeatureSettings?${query}`);
};

const getServerProperties = (ids: string[]) => {
  const query = ids.map(id => `name=${id}`).join('&');
  return axios.get<TServerProp[]>(`webapi/configuration/serverProperty?${query}`);
};

export { getSiteFeatures, getServerProperties };
