import axios from 'axios';

import logger from 'sentry.client.config';

const publicUrls = ['/webapi/public/v1.0/loads/seo/popular-destinations'];

const instance = axios.create();

instance.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response?.status >= 500) {
      const issueName = `${error.response.status} ${error.config?.method.toUpperCase()} ${error.config?.url}`.trim();

      logger.log(issueName, 'error');
    }

    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  request => {
    const isAuthedUser = window.atiUser?.is_user;
    const isPublicUrl = publicUrls.some(publicUrl => request.url?.includes(publicUrl));

    return {
      ...request,
      url: isAuthedUser && !isPublicUrl ? request.url?.replace('/public', '') : request.url,
    };
  },
  error => Promise.reject(error),
);

export default instance;
