import BigNumber from 'bignumber.js';

const base = new BigNumber(2);
const zero = new BigNumber(0);

export function bitSumToArray(bitSum?: string) {
  if (!bitSum) {
    return [];
  }

  const sum = new BigNumber(bitSum);

  return Array.from(sum.toString(2))
    .reverse()
    .map((bit, position) => {
      if (bit === '1') {
        const power = new BigNumber(position);
        return base.pow(power);
      }

      return zero;
    })
    .filter(item => item.gt(zero));
}
