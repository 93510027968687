import isAfter from 'date-fns/isAfter';

import { Subscription } from './Subscriptions/types';

const getSubscriptionPromotionEndDate = (
  subscription: Subscription | Pick<IValidateUserFilterResponse, 'promotionSubscriptionEndDate'>,
) => {
  if ((subscription as Subscription).subscriptionPromotionEndDate !== undefined) {
    return (subscription as Subscription).subscriptionPromotionEndDate;
  } else {
    return (subscription as IValidateUserFilterResponse).promotionSubscriptionEndDate;
  }
};

export const hasActivePromotion = (
  subscription?: Subscription | Pick<IValidateUserFilterResponse, 'promotionSubscriptionEndDate'> | null,
): boolean => {
  if (!subscription) return false;

  const subscriptionPromotionEndDate = getSubscriptionPromotionEndDate(subscription);

  if (subscriptionPromotionEndDate) {
    const endDate = new Date(subscriptionPromotionEndDate);
    const hasPromotionSubscription = isAfter(endDate, new Date());

    return hasPromotionSubscription;
  }

  return false;
};
