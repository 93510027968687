import { MetricsEvent } from 'constants/metrics';

type EventPayload = { event: MetricsEvent; product?: string };

export function sendMetricsEvent(target: MetricsEvent, product?: string) {
  const event = { event: target } as EventPayload;

  if (product) {
    event.product = product;
  }

  try {
    window?.dataLayer?.push(event);
  } catch (error) {}

  if (window.DEBUG_DATA_LAYER) {
    // eslint-disable-next-line no-console
    console.log('SEND METRICS EVENT:', event);
  }
}

export function sendDataLakeEvent(target: MetricsEvent) {
  sendMetricsEvent(target, 'CargoSearch');
}
