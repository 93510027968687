export const appName = process.env.NEXT_PUBLIC_APP_NAME;
const date = process.env.NEXT_PUBLIC_RELEASE_DATE;
const branch = process.env.NEXT_PUBLIC_RELEASE_BRANCH;
const commit = process.env.NEXT_PUBLIC_RELEASE_COMMIT;

const populateRelease = () => {
  window.__APPS__ = window.__APPS__ || {};
  window.__APPS__[appName] = {
    date,
    branch,
    commit,
  };
};

export { populateRelease };
