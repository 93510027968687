import { AxiosRequestConfig } from 'axios';
import { SnakeCasedPropertiesDeep } from 'type-fest';

import { FilterType } from 'store/FilterStore/types';

import axios from '../axios';

export function createFilter(data: ISaveUserFilterRequest) {
  return axios.post<ICreateFilterResponse>('/gw/loads_filters/web/v1.0/loads/filters', data);
}

export function editFilter(id: string, data: TEditFilterRequest) {
  return axios.put(`/gw/loads_filters/web/v1.0/loads/filters/${id}`, data);
}

export function saveFilter(data: ISaveUserFilterRequest) {
  return axios.post<ISaveUserFilterResponse>('/api/userfilters/SaveFilter', data);
}

export function validateFilter(data: SnakeCasedPropertiesDeep<IValidateFilterRequest>, options: AxiosRequestConfig) {
  return axios.post<IValidateFilterResponse>(`/gw/loads_filters/web/v1.0/loads/filters/validate`, data, options);
}

export function validateFilterLegacy(data: IValidateUserFilterRequest, options: AxiosRequestConfig) {
  return axios.post<IValidateUserFilterResponse>('/api/userfilters/query', data, options);
}

export function getSmsParams() {
  return axios.get<TSmsParamsResponse>('/api/userfilters/GetSmsParameters');
}

export function getSubscriptionRates(data: FilterType, options: AxiosRequestConfig) {
  return axios.post<TSubscriptionRatesResponse>('/webapi/v1.0/loads/search/filters/subscription_rates', data, options);
}

export function getMobileDevices() {
  return axios.get<TMobileDevicesResponse>('/api/userfilters/GetFirmMobileDevices');
}

export function getSubscriptionsNotificationsInfo() {
  return axios.get<number>('/api/SubscriptionsNotificationsInfo');
}

// переименовать
export function deleteSubscription(id: string) {
  return axios.delete(`/webapi/v1.0/loads/subscriptions/${id}`);
}

export function createSubscription(filterId: string, data: any) {
  return axios.post(`/gw/loads_filters/web/v1.0/loads/subscriptions/${filterId}`, data);
}

export function getSubscriptionTrialInfo() {
  return axios.get<SnakeCasedPropertiesDeep<TSubscriptionTrialInfo>>(
    '/webapi/v1.0/loads/subscriptions/trial_version_info',
  );
}
