import getEnvironDomain from 'ati-utils/getEnvironDomain';

interface Cookie {
  name: string;
  value: string;
  days: number;
  domain?: string;
}

const getDomain = () => {
  const domain = getEnvironDomain();

  return `.${domain}`;
};

export const cookie = {
  get: (name: string): string | void => {
    if (typeof window !== 'undefined') {
      const value = document.cookie
        .split(';')
        .find(row => row.trim().startsWith(`${name}=`))
        ?.split('=')[1];

      return value;
    }
  },
  set: ({ name, value, days, domain }: Cookie) => {
    if (typeof window !== 'undefined') {
      let date = null;
      let expires = '';

      if (days) {
        date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
      }

      const cookieDomain = domain ? domain : getDomain();
      document.cookie = `${name}=${value}${expires}; path=/; domain=${cookieDomain}`;
    }
  },
};
