import axios from './axios';

export type LoadWithOffer = {
  LoadId: string;
  HasActive: boolean;
};

export const getLoadIdsWithOffer = async () => {
  return axios.get<LoadWithOffer[]>('/webapi/v1.0/loads/loads/responses/with-offer');
};
