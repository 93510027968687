import { makeAutoObservable } from 'mobx';

import { deleteLastSearchRequest, deleteSavedFilterRequest, getFiltersTabsData, getTabsData } from 'api/tabs';
import { SearchToOld } from 'store/FilterStore/Mappers/SearchToOld';
import { QueryData } from 'store/QueryStore/QueryStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { SubscribedFilter } from 'store/Subscriptions/SubscribedFilter';
import { Subscription } from 'store/Subscriptions/types';
import { snakeToCamelObj } from 'utils/objects';

import { RootStore } from '../RootStore';

export type FilterMeta = {
  boardsAvailabilityFlag?: number;
  departmentId: number;
  id: string;
  isCollective: boolean;
  isForceSaving: boolean;
  isManuallySaved: boolean;
  name: string;
  details: string;
};

export type SavedFilter = FilterMeta & {
  filter: QueryData;
  subscription?: Subscription;
};

export type TabsData = {
  savedFilters: SavedFilter[];
  lastSearches: SavedFilter[];
  truckSearches?: SavedFilter[];
};

export enum ViewFilterTypes {
  all = 'all',
  personal = 'personal',
  collective = 'collective',
}

const mapToOldSavedFilter = (savedFilter: any) => {
  const snakeCasedSavedFilter = snakeToCamelObj(savedFilter);

  return {
    ...snakeCasedSavedFilter,
    filter: SearchToOld(snakeCasedSavedFilter.filter),
    boardsAvailabilityFlag: savedFilter.boardsAvailability,
  };
};

class TabsDataStore {
  root;
  data: TabsData | null;
  truckSearches: SavedFilter[];
  selectedFilter: SavedFilter | null;
  viewFilterType: ViewFilterTypes = ViewFilterTypes.all;

  constructor(root: RootStore) {
    this.root = root;
    this.data = {
      savedFilters: [],
      lastSearches: [],
      truckSearches: [],
    };
    this.truckSearches = [];
    this.selectedFilter = null;

    makeAutoObservable(this);
  }

  get savedFilters() {
    return this.data?.savedFilters.map(savedFilter => new SubscribedFilter(this.root, savedFilter));
  }

  get savedFiltersById() {
    const data = this.savedFilters?.reduce<{ [key: string]: SubscribedFilter }>((acc, subscribedFilter) => {
      acc[subscribedFilter.data.id] = subscribedFilter;

      return acc;
    }, {});

    return data;
  }

  get savedFiltersData() {
    return this.data?.savedFilters
      .map(savedFilter => new SubscribedFilter(this.root, savedFilter).data)
      .sort((a, b) => Number(b.instance?.sortingScore) - Number(a.instance?.sortingScore));
  }

  get filtersView() {
    return this[this.viewFilterType];
  }

  get all() {
    return this.savedFiltersData;
  }

  get personal() {
    return this.all?.filter(filter => !filter.isCollective);
  }

  get collective() {
    return this.all?.filter(filter => filter.isCollective);
  }

  setViewFilterType = (viewFilterType: ViewFilterTypes) => {
    this.viewFilterType = viewFilterType;
  };

  setData = (data: TabsData) => {
    this.data = data;
  };

  setTrucksData = (data: SavedFilter[]) => {
    this.truckSearches = data;
  };

  fetchTabsData = async () => {
    this.root.app.setLoading('tabsData', true);
    try {
      if (this.root.siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.newSaveFilterMethod]) {
        const { data } = await getFiltersTabsData();

        this.setData({
          savedFilters: data.saved_filters.map(mapToOldSavedFilter),
          lastSearches: data.last_searches.map(mapToOldSavedFilter),
        });
      } else {
        const { data } = await getTabsData();

        this.setData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.root.app.setLoading('tabsData', false);
    }
  };

  fetchTrucksData = async () => {
    this.root.app.setLoading('tabsData', true);

    try {
      const { data } = await getTabsData();

      this.setTrucksData(data.truckSearches);
    } catch (error) {
      console.error(error);
    } finally {
      this.root.app.setLoading('tabsData', false);
    }
  };

  deleteSavedFilter = async (id: string) => {
    this.root.app.setLoading('deleteSavedFilter', true);
    try {
      const { data } = await deleteSavedFilterRequest(id);

      if (data.success === true) {
        this.fetchTabsData();
      }
    } catch (error: any) {
      const { response } = error;

      if (response?.status === 403) {
        return this.root.errors.setRetrievable({
          name: 'deleteSavedFilterError',
          message: response.data.message,
          type: 'warning',
        });
      }

      this.root.errors.setRetrievable({
        name: 'deleteSavedFilterError',
        message: this.root.app.i18n.errors.deleteSavedFilterError,
      });
      console.error(error);
    } finally {
      this.root.app.setLoading('deleteSavedFilter', false);
    }
  };

  deleteLastSearch = async (id: string) => {
    this.root.app.setLoading('deleteLastSearchRequest', true);
    try {
      const { status } = await deleteLastSearchRequest(id);

      if (status === 200) {
        this.fetchTabsData();
      }
    } catch (error) {
      this.root.errors.setRetrievable({
        name: 'deleteLastSearchRequestError',
        message: this.root.app.i18n.errors.deleteLastSearchRequestError,
      });
      console.error(error);
    } finally {
      this.root.app.setLoading('deleteLastSearchRequest', false);
    }
  };

  appendLastSearch = (lastSearch: SavedFilter) => {
    const alreadySearched = this.data?.lastSearches.filter(search => search.id === lastSearch.id);

    if (alreadySearched?.length) return;

    this.data?.lastSearches.unshift(lastSearch);
    if (this.data?.lastSearches) {
      this.data.lastSearches = this.data?.lastSearches.slice(0, 15);
    }
  };

  selectFilter = (filter: SavedFilter) => {
    this.selectedFilter = filter;
  };

  get subscribedToSelectedFilter() {
    return Boolean(this.selectedFilter?.subscription);
  }
}

export { TabsDataStore };
