export const boardsFaq = 'https://help.ati.{{env}}/service-boards';
export const auctionFaq = 'https://help.ati.{{env}}/auktsiony-v-ati';
export const searchInstruction = 'https://help.ati.{{env}}/kak-najti-podhodjaschij-gruz';
export const searchYoutubeGuide = 'https://www.youtube.com/watch?v=UXeKVp4QnOU&feature=emb_logo';

export const cargosCreate = 'https://loads.ati.{{env}}/cargos/create';
export const traceLink = 'https://ati.{{env}}/Trace?utm_source=loads&utm_campaign=trace';
export const insuranceLink =
  'https://ati.{{env}}/landings/insurances/?utm_source=loads&utm_campaign=insurance_responsibility#rec520004545';
export const ellipseFaq = 'https://help.ati.{{env}}/kak-najti-podhodjaschij-gruz#elips';
export const registration = '//id.ati.{{env}}/register?redir=true&next={{next}}';
export const truckmarketSource = {
  js: 'https://files.ati.{{env}}/static/tm-widgets/bundle.js',
  css: 'https://files.ati.{{env}}/static/tm-widgets/bundle.css',
};
export const newsWidgetSource = {
  js: 'https://files.ati.{{env}}/static/underwood/news_widget.js',
};
export const tendersCardSource = {
  js: 'https://files.ati.{{env}}/static/tenders-card-cargo/index.js',
};

export const notificationsLimitFAQ = 'https://help.ati.{{env}}/uvedomlenija-o-novyh-gruzah#licenziay';
export const loadsSearchFAQ = 'https://help.ati.{{env}}/kak-najti-podhodjaschij-gruz#filters';
export const trucksSearchFAQ = 'https://help.ati.{{env}}/kak-najti-podhodjaschij-gruz#searchbytrack';
export const addTruckPopup =
  'https://trucks.ati.{{env}}/EditPages/EditTruck.aspx?ID=-1&Action=Add&GUI=false&WindowMode=Popup&CloseAct=0';
export const addTruck = 'https://trucks.ati.{{env}}/published?utm_source=empty_cargo_search';

export const atiDriverApp = {
  appIconSrc: 'https://files.ati.su/images/icons/loads-trucks-mobile-app.svg',
  appstore: 'https://redirect.appmetrica.yandex.com/serve/28418966900717464',
  playmarket: 'https://redirect.appmetrica.yandex.com/serve/1037225285075923069',
  rustore: 'https://apps.rustore.ru/app/su.ati.client.android',
  appgallery:
    'https://appgallery.huawei.com/app/C103847285?sharePrepath=ag&locale=ru_RU&source=appshare&subsource=C103847285',
};

export const notificationsFaq = 'https://help.ati.{{env}}/uvedomlenija-o-novyh-gruzah';
export const personalAccountFaq = 'https://help.ati.{{env}}/personal-account-new';
export const addInvoice = 'https://billing.ati.{{env}}/addinvoice';
export const extendedFirmSearchPopup = 'https://ati.{{env}}/extended-search/?mode=select';
export const virtualWalletFaq = `https://help.ati.{{env}}/personal-account-new`;
export const subscriptionStats = 'https://ati.{{env}}/Billing/ViewSubscriptionsStatistics.aspx';
export const editContactPopup = `//ati.{{env}}/office/contact-edit/?id={{contactId}}`;
export const favoritesFAQ = 'https://help.ati.{{env}}/tracked_cargo';
export const adrFaq = 'https://help.ati.{{env}}/kak-najti-podhodjaschij-gruz#adr';
export const emojiFiles = `https://files.ati.{{env}}/static/emoji-script/emojis/{{emoji}}.png`;
