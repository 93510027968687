import axios from '../axios';

function getFeatures() {
  return axios.get('/webapi/enabler/v1.0/features');
}

function markAsViewed(id: number) {
  return axios.post(`/webapi/enabler/v1.0/features/${id}/view`);
}

export { getFeatures, markAsViewed };
