import { makeAutoObservable, reaction } from 'mobx';

import { getGeoCompletionList } from 'api/geo';
import { geoSuggestionTypes } from 'constants/geo';
import { TUikitSupportedLocale } from 'localization/types';
import { IntegerField } from 'store/fileds/IntegerField';
import { SuggestionField } from 'store/fileds/Suggestion';
import type { GeoListType, LocalityType } from 'types/cargosApp/Geo';

import { RootStore } from '../../RootStore';

interface IGeo {
  radius: IntegerField;
  locality: SuggestionField<LocalityType>;
  list: GeoListType | null;
  isExact: boolean;
}

class Geo implements IGeo {
  root;
  locality;
  radius;
  list: GeoListType | null;
  isManuallyExact;

  constructor(root: RootStore) {
    this.root = root;
    this.radius = new IntegerField();
    this.locality = new SuggestionField<LocalityType>({
      rootStore: root,
      onFetch: this.fetchGeoCompletionList,
      onSuggestionSelect: this.handleLocalityChange,
    });
    this.list = null;
    this.isManuallyExact = false;

    reaction(
      () => this.locality.value,
      () => {
        if (this.root.app.isDataSetting) return;

        this.radius.reset();
      },
    );

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.radius.isDefault && this.locality.isDefault && !this.list && !this.isManuallyExact;
  }

  get isExactDisabled() {
    return !this.hasLocality || this.isAutoExact;
  }

  get isValid() {
    return this.list?.id || this.locality.suggestion?.id;
  }

  get error() {
    return this.getError();
  }

  getError(options = { isVirtuallySubmitted: false }) {
    const {
      routeParams,
      extraParams,
      truckType,
      from,
      to,
      isSubmitted: isSubmittedFromStore,
      boards,
      firms,
    } = this.root.filter;

    const isSubmitted = options.isVirtuallySubmitted || isSubmittedFromStore;

    if (extraParams.withADR || truckType.hasOnlyRareTruckTypes) return false;

    if (routeParams.isEllipseActive) return isSubmitted && !this.isValid;

    if (from.isValid || to.isValid) return false;

    return (
      isSubmitted &&
      !this.isValid &&
      !boards.withAuction.data &&
      boards.commonBoard.isSelected.data &&
      !firms.firmName.suggestion &&
      (firms.isSelectedListsDefault || !firms.isExclusiveMode.isDefault)
    );
  }

  get type() {
    if (this.list?.id) return 5;

    return this.locality.suggestion?.type;
  }

  get requestData(): FilterGeoType | null {
    if (!this.isValid) return null;

    const data = {
      id: this.locality.suggestion?.id,
      type: this.type,
      radius: this.radius.data,
      exactOnly: this.isExact,
      listId: this.list?.id,
      listType: this.list?.type,
    };

    return data;
  }

  get requestUserFilterData() {
    return {
      id: `${this.type}_${this.locality.suggestion?.id}`,
      value: this.locality.value,
    };
  }

  get hasLocality() {
    return Boolean(this.locality.suggestion?.id);
  }

  get isCityLocality() {
    return this.locality.suggestion?.type === 2;
  }

  get isExact() {
    if (!this.hasLocality && !this.list) return false;

    return this.isAutoExact || this.isManuallyExact;
  }

  get isAutoExact() {
    return (
      (this.hasLocality && !this.isCityLocality) ||
      Boolean(this.list) ||
      Boolean(this.radius.value) ||
      this.root.filter.routeParams.isEllipseActive
    );
  }

  fetchGeoCompletionList = (prefix: string, locale: TUikitSupportedLocale) => {
    return getGeoCompletionList(prefix, locale, this.root.filter.localityGeoTypes)
      .then(resp => resp.data)
      .catch(() => {
        this.root.errors.setRetrievable({
          name: 'getGeoCompletionList',
          message: this.root.app.i18n.errors.getGeoCompletionList,
        });
      });
  };

  setLocality = (locality: LocalityType) => {
    this.locality.onSuggestionSelected(undefined, { suggestion: locality });

    if (locality.type !== geoSuggestionTypes.list) this.locality.setValue(locality.text);
  };

  setIsExact = (isExact: boolean) => {
    this.isManuallyExact = isExact;
  };

  toggleExact = () => {
    this.setIsExact(!this.isManuallyExact);
  };

  setList = (list: GeoListType) => {
    this.list = list;
  };

  resetList = () => {
    this.list = null;
  };

  clearInvalidList = () => {
    const isListAvailable =
      this.root.dictionaries.data.privateGeoLists.filter(privateList => privateList.id === this.list?.id).length > 0;

    if (!this.list?.elements.length || !isListAvailable) {
      this.resetList();
      this.locality.setValue('');
    }
  };

  handleListChange = (list: GeoListType) => {
    this.locality.setValue(list.name);
    this.locality.resetSuggestion();
    this.setList(list);
  };

  handleLocalityChange = (locality: LocalityType) => {
    if (locality.type === geoSuggestionTypes.list) {
      const { geoLists } = this.root.dictionaries;

      const list = geoLists.filter(({ id }) => id === locality.id)[0];

      this.handleListChange(list);
    } else {
      this.resetList();
    }
  };
}

export { Geo };
