import { makeAutoObservable } from 'mobx';

import { TMinMax } from 'store/FilterStore/types';

import { RootStore } from '../RootStore';
import { FloatField } from './FloatField';

interface IMinMaxField {
  unit?: string;
  default?: { min: string; max: string };
}

class MinMaxField {
  min: FloatField;
  max: FloatField;
  root: RootStore;
  unit?: string;
  default?: {
    min: string;
    max: string;
  };

  constructor(root: RootStore, data?: IMinMaxField) {
    this.min = new FloatField({ default: data?.default?.min });
    this.max = new FloatField({ default: data?.default?.max });
    this.root = root;
    this.unit = data?.unit;

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.min.isDefault && this.max.isDefault;
  }

  get requestUserFilterData() {
    if (!this.min.value && !this.max.value) return null;

    return {
      from: this.min.data,
      to: this.max.data,
    };
  }

  get requestData(): TMinMax | null {
    if (!this.min.value && !this.max.value) return null;

    return {
      min: this.min.data,
      max: this.max.data,
    };
  }

  setData = ({ min, max }: { min?: number | null; max?: number | null }) => {
    this.min.setValue(min || '');
    this.max.setValue(max || '');
  };

  handleSwap = () => {
    if (this.min.data && this.max.data && this.min.data > this.max.data) {
      const temp = this.max;
      this.max = this.min;
      this.min = temp;
    }
  };
}

export { MinMaxField };
