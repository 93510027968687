export const stikyHeaderHeight = '36px';

export const defaultScrollToOpts: ScrollOptions = {
  preventDefault: true,
  smooth: true,
  block: 'center',
};

export const searchScrollAnchorId = 'search-scroll-anchor';
export const scrollTopAnchorId = 'scroll-top-anchor';
export const staticTabsId = 'static-tabs';

export interface ScrollOptions {
  preventDefault?: boolean;
  smooth?: boolean;
  block?: ScrollLogicalPosition;
}

export const itemsPerPageCookieName = 'itemsPerPage';
export const defaultItemsPerPage = '10';

export const mediaQuery = {
  mobile: '(max-width: 890px)',
  filtersTablet: '(max-width: 927px)',
  filtersMobile: '(max-width: 644px)',
};

export const columnsCount = {
  mobile: 1,
  desktop: 3,
  tablet: 2,
} as const;

export const tabIds = {
  search: 'search',
  savedFilters: 'savedFilters',
  history: 'history',
  byTrucks: 'byTrucks',
  favorites: 'favorites',
  chains: 'chains',
} as const;
