import { action, makeObservable } from 'mobx';

import { Field, IField } from './Field';

class StringField extends Field {
  constructor(data: IField = {}) {
    super(data);
    makeObservable(this, {
      setValue: action,
    });
  }

  setValue(value: string) {
    this.value = value;
  }
}

export { StringField };
