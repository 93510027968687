import { makeAutoObservable, when } from 'mobx';
import { nanoid } from 'nanoid';

import { getSubscriptionsCounters } from 'api/subscriptions';

import { RootStore } from '../RootStore';
import { NotificationLevels, TNotification, TSubscribedFilter } from './SubscribedFilter';
import { SubscriptionsCounter } from './types';

export class Subscriptions {
  readonly #root: RootStore;
  counters: SubscriptionsCounter | null;
  isCountersInitialized: boolean = false;
  isCountersLoading: boolean = false;
  isFirstRequest: boolean = true;
  fetchInterval: number = 20_000;
  fetchTimeout: number | null = null;
  readonly tabId: string = nanoid();
  readonly tabGroupName: string = 'counters';

  constructor(root: RootStore) {
    this.#root = root;
    this.counters = null;

    makeAutoObservable(this);

    when(() => this.shouldPollCounters, this.pollCounters);
  }

  get shouldPollCounters() {
    return (
      this.hasSubscriptions || (this.hasSavedFilters && Boolean(this.#root.profile.hasUnlimitedNotificationsLicense))
    );
  }

  get shouldSkipCountersRequest() {
    if (this.isFirstRequest) return false;

    const isPageHidden = document.visibilityState === 'hidden';

    return isPageHidden || !this.#root.ui.isPageFocused || !this.#root.profile.data?.isUser;
  }

  get pollingInterval() {
    return this.isOnSlowPolling ? this.fetchInterval * 5 : this.fetchInterval;
  }

  get isOnSlowPolling() {
    if (!this.counters?.statisticCounters) return false;

    if (
      Boolean(this.#root.profile.hasUnlimitedNotificationsLicense) &&
      this.#root?.tabsData?.savedFilters?.some(filter => !filter.hasSubscription)
    ) {
      return false;
    }

    const isStopped =
      this.#root?.tabsData?.savedFilters?.length &&
      this.#root?.tabsData?.savedFilters
        ?.filter(filter => filter.hasSubscription)
        ?.every(
          subscribedFilter =>
            subscribedFilter.isLimitReached.active ||
            subscribedFilter.hasNotEnoughMoney.active ||
            subscribedFilter.isVirtuallyBlocked.active,
        );

    return isStopped;
  }

  get hasSavedFilters() {
    return Boolean(this.#root.tabsData.data?.savedFilters.length);
  }

  get hasSubscriptions() {
    return Boolean(this.#root.tabsData.data?.savedFilters.some(savedFilter => savedFilter.subscription));
  }

  get allNotifications() {
    return this.#root.tabsData.savedFiltersData?.reduce((acc: TNotification[], value: TSubscribedFilter) => {
      return acc.concat(value.notifications || []);
    }, []);
  }

  get hasError() {
    return Boolean(this.allNotifications?.length);
  }

  get hasUpdatedLoads() {
    return this.#root.tabsData.savedFiltersData?.some((filter: TSubscribedFilter) => filter?.counters?.hasUpdatedLoads);
  }

  get hasNewLoads() {
    return this.#root.tabsData.savedFiltersData?.some((filter: TSubscribedFilter) => filter?.counters?.hasNewLoads);
  }

  get hasLoadsUpdates() {
    return this.#root.tabsData.savedFiltersData?.some((filter: TSubscribedFilter) => {
      const { counters } = filter;

      return counters && filter.instance?.hasCounters && filter.subscription;
    });
  }

  get warningNotifications() {
    return this.allNotifications?.filter(not => not.level === NotificationLevels.warning);
  }

  get criticalNotifications() {
    return this.allNotifications?.filter(not => not.level === NotificationLevels.critical);
  }

  setCounters = (counters: SubscriptionsCounter) => {
    this.counters = counters;
  };

  fetchCounters = async () => {
    if (this.isFirstRequest) {
      this.setIsCountersLoading(true);
    }

    try {
      const { data } = await getSubscriptionsCounters();

      this.setCounters(data);

      this.setIsCountersInitialized(true);
    } catch (error) {
      console.error(error);
    } finally {
      if (this.isFirstRequest) {
        this.setIsCountersLoading(false);
        this.setIsFirstRequest(false);
      }
    }
  };

  pollCounters = async () => {
    if (this.shouldPollCounters) {
      if (!this.shouldSkipCountersRequest) {
        await this.fetchCounters();
      }
    }

    const fetchTimeout = window.setTimeout(this.pollCounters, this.pollingInterval);

    this.setFetchTimeout(fetchTimeout);
  };

  setIsCountersInitialized = (value: boolean) => {
    this.isCountersInitialized = value;
  };

  setIsCountersLoading = (value: boolean) => {
    this.isCountersLoading = value;
  };

  setIsFirstRequest = (value: boolean) => {
    this.isFirstRequest = value;
  };

  setFetchTimeout = (value: number) => {
    this.fetchTimeout && clearTimeout(this.fetchTimeout);
    this.fetchTimeout = value;
  };
}
