import { makeAutoObservable } from 'mobx';

import { BoardType } from 'components/Board/types';
import { commonBoardColor, commonBoardId } from 'constants/boards';
import type { RootStore } from 'store/RootStore';
import { BooleanField } from 'store/fileds/BooleanField';

class Board {
  root: RootStore;
  isSelected: BooleanField;
  isDisabled: BooleanField;
  isCommon: boolean;
  id: string;
  boardName: string;
  color: string;
  availableForContact: boolean;

  constructor(root: RootStore, board: BoardType) {
    this.root = root;
    this.id = board.id;
    this.boardName = board.name;
    this.color = board.color;
    this.isDisabled = new BooleanField({ default: false });
    this.isSelected = new BooleanField({ default: true });
    this.availableForContact = Boolean(board.availableForContact);
    this.isCommon = Boolean(board.isCommon);

    if (board.isCommon) {
      this.color = commonBoardColor;
      this.id = commonBoardId;
    }

    makeAutoObservable(this);
  }

  get name() {
    if (this.isCommon) {
      return this.root.app.i18n.boards.commonBoard;
    }

    return this.boardName;
  }

  get isBoardDisabled() {
    if (this.isCommon && this.root.filter.boards.boards.length <= 1) {
      return true;
    }

    return this.isDisabled.value;
  }
}

export { Board };
