import { makeAutoObservable } from 'mobx';

import { atiSearchGeoStorageName, excludedReferrer } from 'constants/geo';
import { RootStore } from 'store/RootStore';
import { cookie } from 'utils/storage';

class GeoInfomer {
  root: RootStore;
  isVisible: boolean;

  constructor(root: RootStore) {
    this.root = root;
    this.isVisible = false;

    makeAutoObservable(this);
  }

  get geoInfo() {
    const rawGeoInfo = localStorage.getItem(atiSearchGeoStorageName);

    if (rawGeoInfo) {
      const geoInfo = JSON.parse(rawGeoInfo);

      return geoInfo;
    }

    return {
      fromGeoValue: null,
      fromGeoPopupText: null,
      isExactFrom: null,
      inputFromText: null,
    };
  }

  setIsVisible = (value: boolean) => {
    this.isVisible = value;
  };

  setFromLocation = (localityValue: string) => {
    const [type, id] = this.geoInfo.fromGeoValue.split('_');
    const fromLocality = { id, type, text: localityValue, value: this.geoInfo.fromGeoValue };

    this.root.app.setIsDataSetting(true);
    this.root.filter.from.locality.setValue(localityValue);
    this.root.filter.from.setIsExact(this.geoInfo.isExactFrom);
    this.root.filter.from.setLocality(fromLocality);
    this.root.app.setIsDataSetting(false);
  };

  searchLoads = () => {
    cookie.set({ name: atiSearchGeoStorageName, value: 'true', days: 1 });
    this.setIsVisible(false);
    this.setFromLocation(this.geoInfo.inputFromText);
    this.root.app.searchLoads();
  };

  cancelInformer = () => {
    cookie.set({ name: atiSearchGeoStorageName, value: 'false', days: 1 });
    this.setIsVisible(false);
  };

  init = () => {
    const cookieValue = cookie.get(atiSearchGeoStorageName);
    const isGeoAutoSuggestedAlready = Boolean(cookieValue);
    const isUserAcceptedGeo = cookieValue === 'true';
    const referrer = window.document.referrer;
    const isAtiReferrer = referrer.includes(excludedReferrer) || referrer === '';
    const isGeoInfoExists = Boolean(
      this.geoInfo.fromGeoValue && this.geoInfo.fromGeoPopupText && this.geoInfo.inputFromText,
    );

    if (isAtiReferrer) return;

    if (isGeoAutoSuggestedAlready) {
      if (isUserAcceptedGeo && isGeoInfoExists) {
        this.searchLoads();
      }

      return;
    }

    if (!isGeoInfoExists) {
      cookie.set({ name: atiSearchGeoStorageName, value: 'false', days: 1 });

      return;
    }

    this.setIsVisible(isGeoInfoExists);
  };
}

export { GeoInfomer };
