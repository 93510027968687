import { makeAutoObservable } from 'mobx';

class SelectedLoads {
  ids: string[];

  constructor() {
    this.ids = [];

    makeAutoObservable(this);
  }

  get count() {
    return this.ids.length;
  }

  toggleSelection = (loadsIds: string[], isSelected: boolean) => {
    if (isSelected) {
      this.select(loadsIds);
    } else {
      this.unselect(loadsIds);
    }
  };

  select = (loadsIds: string[]) => {
    loadsIds.forEach(id => {
      if (!this.ids.includes(id)) {
        this.ids.push(id);
      }
    });
  };

  unselect = (loadsIds: string[]) => {
    this.ids = this.ids.filter(id => !loadsIds.includes(id));
  };

  unselectAll = () => {
    this.ids = [];
  };
}

export { SelectedLoads };
