import { makeAutoObservable, reaction } from 'mobx';

import { RootStore } from 'store/RootStore';
import { BooleanField } from 'store/fileds/BooleanField';

declare enum ChannelOptions {
  site = 'ati.su',
  email = 'email',
  mobile = 'mobile_devices',
  sms = 'sms',
}

export class Channels {
  root: RootStore;
  site: BooleanField;
  email: BooleanField;
  mobile: BooleanField;
  sms: BooleanField;

  constructor(root: RootStore) {
    this.root = root;
    this.site = new BooleanField({ default: true });
    this.email = new BooleanField();
    this.mobile = new BooleanField();
    this.sms = new BooleanField();

    makeAutoObservable(this);

    reaction(
      () => this.root.mobileDevices.hasMobileAppInstalled,
      () => {
        this.mobile.setData(this.root.mobileDevices.hasMobileAppInstalled);
      },
    );
  }

  get isOneChannelSelected() {
    return [this.site.value, this.email.value, this.mobile.value, this.sms.value].filter(Boolean).length === 1;
  }

  get data() {
    return [
      this.site.value && ChannelOptions.site,
      this.email.value && ChannelOptions.email,
      this.mobile.value && ChannelOptions.mobile,
      this.sms.value && ChannelOptions.sms,
    ].filter(Boolean);
  }

  setData = (contacts: TSavedUserFilterContact[]) => {
    const [currentUserChannels] = contacts.filter(contact => contact.contactId === this.root.profile.contact?.id);

    this.site.setData(currentUserChannels.channels.includes(ChannelOptions.site));
    this.email.setData(currentUserChannels.channels.includes(ChannelOptions.email));
    this.mobile.setData(currentUserChannels.channels.includes(ChannelOptions.mobile));
    this.sms.setData(currentUserChannels.channels.includes(ChannelOptions.sms));
  };
}
