import numberFormat from 'ati-utils/numberFormat';

import { locales } from 'constants/common';

const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);
const uncapitalize = (str: string) => str.substr(0, 1).toLowerCase() + str.substr(1);

const interpolate = (str: string, values: { [key: string]: string }) =>
  str.replace(/{{([\w\d]+)}}/g, (_, group) => values[group]);

const snakeToPascal = (str: string) => str.split('_').map(capitalize).join('');

export const pascalToSnake = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .map(str => str.toLowerCase())
    .join('_');

export const snakeToCamel = (str: string) => uncapitalize(snakeToPascal(str));

export const toMoneyFormat = (num: number | string, locale = 'ru') => {
  const preformatted = numberFormat(num);

  const delimiter = locale === locales.ru ? ',' : '.';

  const [firstPart, floatPart = '00'] = preformatted.split('.');

  return [firstPart, floatPart?.padEnd(2, '0')].filter(Boolean).join(delimiter);
};

export const insertNoBreakAfterShortWords = (str: string) =>
  str
    .split(' ')
    .map(word => (word.length <= 2 ? `${word} ` : `${word} `))
    .join('');

export const camelToKebab = (str: string) => pascalToSnake(str).replace('_', '-');

export { capitalize, interpolate, uncapitalize };
