import debounce from 'ati-utils/debounce';
import { makeAutoObservable } from 'mobx';
import { SyntheticEvent } from 'react';

import type { TUikitSupportedLocale } from 'localization/types';
import { RootStore } from 'store/RootStore';

interface ISuggestionField<TSuggestion, TSuggestions> {
  rootStore: RootStore;
  onFetch: (value: string, locale: TUikitSupportedLocale) => Promise<void | TSuggestions>;
  onSuggestionSelect?: (suggestion: TSuggestion) => void;
}

class SuggestionField<TSuggestion, TSuggestions = TSuggestion[]> {
  root;
  suggestion: TSuggestion | null;
  suggestions: TSuggestions | [];
  onFetch;
  onSuggestionSelect;
  default = '';
  value = '';

  constructor(data: ISuggestionField<TSuggestion, TSuggestions>) {
    this.root = data.rootStore;
    this.onFetch = data.onFetch;
    this.onSuggestionSelect = data.onSuggestionSelect;
    this.suggestions = [];
    this.suggestion = null;

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.value === this.default;
  }

  onSuggestionSelected = (_event: SyntheticEvent<Element> | undefined, { suggestion }: { suggestion: TSuggestion }) => {
    this.setSuggestion(suggestion);
    this.resetSuggestions();
    this.onSuggestionSelect && this.onSuggestionSelect(suggestion);
  };

  onFetchRequested = debounce(() => {
    this.onFetch(this.value, this.root.app.uiComponentsLocale).then(suggestions => {
      if (suggestions) {
        this.setSuggestions(suggestions);
      }
    });
  }, 300);

  setValue = (value: string) => {
    this.value = value;
  };

  setSuggestions = (suggestions: TSuggestions | []) => {
    this.suggestions = suggestions;
  };

  setSuggestion = (suggestion: TSuggestion | null) => {
    this.suggestion = suggestion;
  };

  resetSuggestions = () => {
    this.setSuggestions([]);
  };

  resetSuggestion = () => {
    this.setSuggestion(null);
  };

  clear = () => {
    this.setValue('');
    this.resetSuggestion();
  };
}

export { SuggestionField };
