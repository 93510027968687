import { format, startOfToday } from 'date-fns';
import { makeAutoObservable } from 'mobx';

import { RootStore } from 'store/RootStore';

import { getDates } from './dates';

const defaultDateOption = 'today-plus';

class LoadDates {
  root;
  fromDate: Date | null;
  toDate: Date | null;
  dateOption: DateOptionId;

  constructor(root: RootStore) {
    this.root = root;
    this.fromDate = null;
    this.toDate = null;
    this.dateOption = defaultDateOption;

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.dateOption === defaultDateOption;
  }

  setDateOption = (option: DateOptionId) => {
    if (option !== 'manual') {
      this.resetDates();
    } else {
      this.setFromDate(startOfToday());
    }

    this.dateOption = option;
  };

  setFromDate = (date: Date | null) => {
    if (date) {
      this.fromDate = date;
    }
  };

  setToDate = (date: Date | null) => {
    this.toDate = date;
  };

  resetDates = () => {
    this.toDate = null;
    this.fromDate = null;
  };

  get isManualDateActive() {
    return this.dateOption === 'manual';
  }

  get requestData() {
    const fromDate = this.fromDate ? format(this.fromDate, 'yyyy-MM-dd') : null;
    const toDate = this.toDate ? format(this.toDate, 'yyyy-MM-dd') : null;

    const data = {
      dateFrom: fromDate,
      dateTo: toDate,
      dateOption: this.dateOption,
    };

    return data;
  }

  get requestUserFilterData() {
    const fromDate = this.fromDate ? format(this.fromDate, 'yyyy-MM-dd') : null;
    const toDate = this.toDate ? format(this.toDate, 'yyyy-MM-dd') : null;

    return {
      dateOption: this.dateOption,
      dateFrom: fromDate || '1900-01-01',
      ...(toDate && { dateTo: toDate }),
    };
  }

  get dateOptions() {
    return getDates(this.root.app.i18n.dates.optionsCaption);
  }

  get selectedDate() {
    return this.dateOptions.find(date => date.id === this.dateOption);
  }
}

export { LoadDates };
