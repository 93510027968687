import { action, makeObservable } from 'mobx';

import { Field, IField } from './Field';

class FloatField extends Field {
  constructor(data: IField = {}) {
    super(data);
    makeObservable(this, {
      setValue: action,
    });
  }

  get data() {
    if (!this.value || !this.value.trim()) return null;

    return parseFloat(this.value);
  }

  get view() {
    return this.value?.toString();
  }

  setValue = (value: number | string) => {
    const normalizedValue = value.toString().trim().replace(',', '.');

    this.value = normalizedValue;
  };
}

export { FloatField };
