import addDays from 'date-fns/addDays';

const currentDate = new Date();
const today = currentDate;
const tomorrow = addDays(currentDate, 1);
const twoDaysLater = addDays(currentDate, 2);
const tomorrowOneDay = addDays(tomorrow, 1);

const isUnclosed = true;
export const dates: DateOption[] = [
  {
    id: 'today-plus',
    start: today,
    isUnclosed,
    caption: '',
  },
  {
    id: 'today',
    start: today,
    caption: '',
  },
  {
    id: 'today-tomorrow',
    start: today,
    end: tomorrow,
    caption: '',
  },
  {
    id: 'today-two-days',
    start: today,
    end: twoDaysLater,
    caption: '',
  },
  {
    id: 'tomorrow-plus',
    start: tomorrow,
    isUnclosed,
    caption: '',
  },
  {
    id: 'tomorrow',
    start: tomorrow,
    caption: '',
  },
  {
    id: 'tomorrow-one-day',
    start: tomorrow,
    end: tomorrowOneDay,
    caption: '',
  },
  {
    id: 'manual',
    start: today,
    caption: '',
  },
];

export const getDates = (captions: string[]) => {
  return dates.map((date, index) => {
    date.caption = captions[index];
    return date;
  });
};
