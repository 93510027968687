import { ScrollOptions, defaultScrollToOpts } from 'constants/ui';

export const scrollTo = (selector: string, opts: ScrollOptions = {}) => {
  const element = document.querySelector<HTMLElement>(selector);

  if (!element) return;

  const smooth = opts.smooth ?? defaultScrollToOpts.smooth;

  element.focus({ preventScroll: true });
  element.scrollIntoView({
    behavior: smooth ? 'smooth' : 'auto',
    block: opts.block ?? defaultScrollToOpts.block,
  });
};

export const openPopupOnCenter = ({ url, w, h }: { url: string; w: number; h: number }) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  window.open(
    url,
    '_blank',
    `
    scrollbars=yes,
    width=${w / systemZoom},
    height=${h / systemZoom},
    top=${top},
    left=${left}
    `,
  );
};
