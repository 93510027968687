export const distanceOptions = {
  percent: 1,
  km: 2,
};

export const maxEnlargment = 15;

export const minLength = 30;

export const minRouteLength = 150;

export const maxRouteLength = 3000;

export const countryIds = {
  russia: '1',
};

export const geoTypes = {
  cities: 1,
  districts: 2,
  countries: 4,
  directions: 8,
  lists: 16,
};
