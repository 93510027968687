import { makeAutoObservable, reaction } from 'mobx';

import { extraParams } from 'constants/extraParams';
import { currenciesModifiers, paymentTypes } from 'constants/payment';
import { RootStore } from 'store/RootStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { bitSumToArray } from 'utils/numbers';

import { ADR } from './ADR';

const paymentTypesWithRate = [paymentTypes.cash, paymentTypes.rateWithNDS, paymentTypes.rateWithoutNDS];

class ExtraParams {
  root;
  params: Array<string>;
  ADR;

  constructor(root: RootStore) {
    this.root = root;
    this.params = [];

    this.ADR = new ADR(this.root);

    makeAutoObservable(this);

    reaction(
      () => JSON.stringify(this.hasPaymentTypesWithRate),
      () => {
        if (this.hasPaymentTypesWithRate.some(Boolean) && !this.withRate) {
          this.addParam(paymentTypes.withRate);
        }
      },
    );

    reaction(
      () => this.withRate,
      () => {
        if (this.root.app.isDataSetting) return;

        if (!this.withRate) {
          this.root.filter.rate.reset();
          this.removePaymentTypesWithRate();
        }
      },
    );
  }

  get hasPaymentType() {
    return this.root.options.getPaymentTypes().some(paymentType => this.params.includes(paymentType.id));
  }

  get hasExtraParam() {
    return this.root.options.getExtraParams().some(extraParam => this.params.includes(extraParam.id));
  }

  get hasPaymentTypesWithRate() {
    return paymentTypesWithRate.map(paymentType => this.params.includes(paymentType));
  }

  removePaymentTypesWithRate() {
    paymentTypesWithRate.forEach(paymentType => this.removeParam(paymentType));
  }

  setParam = (param: string) => {
    if (this.params.includes(param)) {
      this.removeParam(param);
    } else {
      this.addParam(param);
    }
  };

  setParams = (params: string[]) => {
    this.params = params;
  };

  setParamsFromBitSum = (bitSum: string) => {
    const params = bitSumToArray(bitSum);
    const stringParams: string[] = [];

    params.forEach(param => {
      const stringParam = param.toString();

      if (stringParam === extraParams.isRateInThousands) {
        this.root.filter.rate.setCurrencyModifier(currenciesModifiers.thousands);
      } else if (
        stringParam === extraParams.adr &&
        this.root.siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.extendedADR]
      ) {
        stringParams.push(extraParams.withAdr);
      } else {
        stringParams.push(stringParam);
      }
    });

    this.setParams(stringParams);
  };

  addParam = (param: string) => {
    this.params = [...this.params, param];
  };

  removeParam = (param: string) => {
    this.params = this.params.filter(value => value !== param);
  };

  get excludeTenders() {
    return this.params.includes(extraParams.tenders);
  }

  get withRate() {
    return this.params.includes(paymentTypes.withRate);
  }

  get withADR() {
    return this.params.includes(extraParams.adr) || this.params.includes(extraParams.withAdr);
  }

  get requestData() {
    let sum = this.params
      .map(param => parseInt(param, 10))
      .filter(Number)
      .reduce((sum, param) => sum + param, 0);

    if (this.root.filter?.rate.isRateInThousands) {
      sum += parseInt(extraParams.isRateInThousands);
    }

    if (this.root.siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.extendedADR]) {
      if (this.params.includes(extraParams.adr)) {
        sum -= parseInt(extraParams.adr);
        this.addParam(extraParams.withAdr);
      }
    }

    return {
      extraParams: sum,
      excludeTenders: this.excludeTenders,
      ...(this.ADR.requestData && { adr: this.ADR.requestData }),
    };
  }

  resetExtraParams = () => {
    this.root.options.getExtraParams().forEach(param => {
      this.removeParam(param.id);
    });
  };

  resetPaymentParams = () => {
    this.root.options.getPaymentTypes().forEach(param => {
      this.removeParam(param.id);
    });
    this.root.filter.rate.reset();
  };
}

export { ExtraParams };
