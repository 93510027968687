import { makeAutoObservable } from 'mobx';

import { getFeatures, markAsViewed } from 'api/enabler/enabler';
import { snakeToCamelObj } from 'utils/objects';

export class EnablerFeaturesRepo {
  features: TEnablerFeature[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setFeatures = (features: TEnablerFeature[]) => {
    this.features = features;
  };

  get featuresByTitle() {
    return this.features.reduce((acc, value) => {
      acc[value.title] = value;
      return acc;
    }, {} as { [key: string]: TEnablerFeature });
  }

  fetchFeatures = async () => {
    try {
      const { data } = await getFeatures();

      this.setFeatures(snakeToCamelObj(data));
    } catch (error) {
      console.error(error);
    }
  };

  markAsViewedByTitle = async (title: string) => {
    const feature = this.features.find(f => f.title === title);

    if (feature) {
      await markAsViewed(feature.id).then(() => {
        this.fetchFeatures();
      });
    }
  };
}
