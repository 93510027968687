import { makeAutoObservable } from 'mobx';
import { ChangeEvent } from 'react';

export interface IBooleanField {
  default: boolean;
}

class BooleanField {
  default: boolean;
  value: boolean;

  constructor(data: IBooleanField = { default: false }) {
    this.default = data.default;
    this.value = this.default;

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.value === this.default;
  }

  get data() {
    return this.value;
  }

  setData = (data?: boolean) => {
    if (typeof data === 'undefined') return;
    this.value = data;
  };

  setValueFromCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    this.value = event.target.checked;
  };

  reset = () => {
    this.value = this.default;
  };
}

export { BooleanField };
