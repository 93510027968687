import { makeAutoObservable, reaction } from 'mobx';
import { SyntheticEvent } from 'react';

import { AdditionalFilters } from 'store/FilterStore/FilterStore';

export const mobileScreenNames = {
  ...AdditionalFilters,
  additionalFilters: 'additionalFilters',
};

export type MobileScreenNames = typeof mobileScreenNames;
export type MobileScreenName = keyof MobileScreenNames | null;

class MobileScreens {
  current: MobileScreenName;
  previous: MobileScreenName;

  constructor() {
    this.current = null;
    this.previous = null;

    makeAutoObservable(this);

    reaction(
      () => this.current,
      (_currentScreen, prevScreen) => {
        this.setPrevious(prevScreen);
      },
    );
  }

  setPrevious = (previous: MobileScreenName) => {
    this.previous = previous;
  };

  setCurrent = (current: MobileScreenName) => {
    this.current = current;
  };

  showScreenFromElementAttribute = (event: SyntheticEvent<HTMLButtonElement>) => {
    this.showScreen(event?.currentTarget?.dataset?.screen as MobileScreenName);
  };

  showScreen = (screenName: MobileScreenName) => {
    this.setCurrent(screenName);
  };

  goBack = () => {
    this.showScreen(this.previous);
  };

  closeScreens = () => {
    this.setCurrent(null);
  };
}

export { MobileScreens };
