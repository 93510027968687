import axios from 'axios';

import { LocaleType } from '../i18n';
import { camelToKebab } from '../utils';

const fetchDictionary = (name: TDictionaryName, env: string, locale: LocaleType) => {
  let restrictedLocale = locale;
  if (name === 'currencyCountry') {
    restrictedLocale = 'ru';
  }

  return axios.get<TDictionariesResponse>(
    `https://files.ati.${env}/glossary/${restrictedLocale}/${camelToKebab(name)}.json`,
  );
};

export { fetchDictionary };
