import cn from 'clsx';
import React, { Component, ReactNode } from 'react';

import { useStore } from 'hooks/useStore';
import logger from 'sentry.client.config';

import { NoneImg } from './NoneImg';

import css from './ErrorBoundary.module.css';

interface ErrorState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

const Fallback = () => {
  const {
    app: { i18n },
  } = useStore();

  return (
    <main className={css.container}>
      <div className={css.errorBoundary}>
        <NoneImg />
        <p className={cn(css.heading, 'glz-p glz-is-size-2')}>{i18n.criticalError.heading}</p>
        <p className={cn(css.desc, 'glz-p glz-is-size-3 glz-has-text-dark-gray')}>{i18n.criticalError.desc}</p>
      </div>
    </main>
  );
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch = (error: Error) => {
    logger.error(error);
    this.setState({ hasError: true });
  };

  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Fallback />;
    }

    return children;
  }
}

export { ErrorBoundary };
