import axios from '../axios';

type GetFavoritesParams = {
  take?: number;
  skip?: number;
};

const getFavorites = (params?: GetFavoritesParams) => axios.get('/gw/favorites/web/v1/cargos/favorites', { params });

const deleteUnactualFavorites = () => axios.delete('/gw/favorites/web/v1/cargos/favorites/not-actual');

const deleteAllFavorites = () => axios.delete('/gw/favorites/web/v1/cargos/favorites/all');

const deleteFavoritesById = (ids: string[]) => axios.delete('/gw/favorites/web/v1/cargos/favorites', { data: ids });

export { getFavorites, deleteUnactualFavorites, deleteAllFavorites, deleteFavoritesById };
