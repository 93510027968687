import { makeAutoObservable, reaction } from 'mobx';

import { maxEnlargment, maxRouteLength, minLength, minRouteLength } from 'constants/routeParams';
import { IntegerField } from 'store/fileds/IntegerField';
import { MinMaxField } from 'store/fileds/MinMaxField';
import { SelectField } from 'store/fileds/SelectField';

import { RootStore } from '../../RootStore';

class RouteParams {
  root;
  routeLength;
  maxEnlargmentUnit;
  maxEnlargment;
  minLength;
  minLengthUnit;
  isRouteLengthActive = false;
  isEllipseActive = false;
  onRouteActive;
  onEllipseActive;

  constructor(root: RootStore) {
    this.root = root;
    this.routeLength = new MinMaxField(root, {
      default: { min: minRouteLength.toString(), max: maxRouteLength.toString() },
    });

    this.maxEnlargment = new IntegerField({ default: maxEnlargment.toString() });
    this.maxEnlargmentUnit = new SelectField(this.root.options.getEllipseDistance);

    this.minLength = new IntegerField({ default: minLength.toString() });
    this.minLengthUnit = new SelectField(this.root.options.getEllipseDistance);

    makeAutoObservable(this);

    this.onRouteActive = reaction(
      () => this.isRouteLengthActive,
      () => {
        if (this.root.app.isDataSetting) return;

        if (this.isRouteLengthActive) {
          this.setInitialRouteLength();
        }
      },
    );

    this.onEllipseActive = reaction(
      () => this.isEllipseActive,
      () => {
        if (this.root.app.isDataSetting) return;

        if (this.isEllipseActive) {
          this.setInitialEllipse();

          const { from, to } = this.root.filter;

          [from, to].forEach(geo => {
            if (!geo.isCityLocality) {
              geo.resetList();
              geo.locality.clear();
            }
          });
        }
      },
    );
  }

  get isDefault() {
    return (
      this.routeLength.isDefault &&
      this.maxEnlargment.isDefault &&
      this.maxEnlargmentUnit.isDefault &&
      this.minLength.isDefault &&
      this.minLengthUnit.isDefault
    );
  }

  get ellipseData(): TEllipse {
    return {
      maxEnlargment: this.maxEnlargment.data,
      maxEnlargmentUnit: this.maxEnlargmentUnit.data,
      minLength: this.minLength.data,
      minLengthUnit: this.minLengthUnit.data,
    };
  }

  get isRouteParamActive() {
    return this.isEllipseActive || this.isRouteLengthActive;
  }

  get requestData() {
    if (this.isRouteLengthActive) {
      return {
        routeLength: this.routeLength.requestData,
      };
    }

    if (this.isEllipseActive) {
      return {
        ellipse: this.ellipseData,
      };
    }

    return null;
  }

  get requestUserFilterData() {
    return {
      ellipse: {
        enabled: this.isEllipseActive,
        maxEnlargment: this.ellipseData.maxEnlargment || 0,
        maxEnlargmentUnit: this.ellipseData.maxEnlargmentUnit || 1,
        minLength: this.ellipseData.minLength || 0,
        minLengthUnit: this.ellipseData.minLengthUnit || 1,
        units: [
          { key: 1, value: '%' },
          { key: 2, value: this.root.app.i18n.common.km },
        ],
      },
      routeParams: {
        enabled: this.isRouteLengthActive,
        ...(this.isRouteLengthActive && this.routeLength.requestData?.min && { min: this.routeLength.requestData.min }),
        ...(this.isRouteLengthActive && this.routeLength.requestData?.max && { max: this.routeLength.requestData.max }),
      },
    };
  }

  setIsEllipseActive = (value: boolean) => {
    this.isRouteLengthActive = false;
    this.isEllipseActive = value;
  };

  setIsRouteLengthActive = (value: boolean) => {
    this.isEllipseActive = false;
    this.isRouteLengthActive = value;
  };

  setInitialEllipse = () => {
    this.maxEnlargment.setValue(maxEnlargment);
    this.minLength.setValue(minLength);
  };

  setInitialRouteLength = () => {
    this.routeLength.min.setValue(minRouteLength);
    this.routeLength.max.setValue(maxRouteLength);
  };
}

export { RouteParams };
