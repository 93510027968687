import { SnakeCasedPropertiesDeep } from 'type-fest';

import { snakeToCamelObj } from 'utils/objects';

import axios from './axios';

const fetchContact = () => {
  return axios
    .get<SnakeCasedPropertiesDeep<TFirmContact>>('/webapi/firms/v1.0/firms/contact')
    .then<TFirmContact>(res => snakeToCamelObj(res.data));
};

export { fetchContact };

export type TFirmContact = {
  eMail: string;
  userRights: bigint;
};
