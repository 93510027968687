import { OptionType } from '@components/select/dist/types';
import { makeAutoObservable } from 'mobx';

import { extraParams } from 'constants/extraParams';
import { RootStore } from 'store/RootStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { MultiSelectChangeData } from 'types/@components/common';

class ADR {
  selectedOptions: OptionType[];
  root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
    this.selectedOptions = [];

    makeAutoObservable(this);
  }

  get hasSelectedOption() {
    return this.selectedOptions.length;
  }

  get isAllOptionsSelected() {
    const allOptionsLength = this.root.options.getADRTypes().length;
    const selectedOptionsLength = this.selectedOptions.length;

    return allOptionsLength === selectedOptionsLength;
  }

  get requestData() {
    if (this.root.siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.extendedADR]) {
      if (this.root.filter.extraParams.withADR) {
        const selectedIds = this.isAllOptionsSelected
          ? null
          : this.selectedOptions.filter(option => option.value).map(option => option.value as number);

        return {
          type: 'include',
          ...(this.hasSelectedOption && { ids: selectedIds }),
        };
      }
    }

    return null;
  }

  setADR = (adr: { type: 'include'; ids?: number[] }) => {
    this.root.filter.extraParams.addParam(extraParams.withAdr);
    this.selectOptionsByIds(adr.ids);
  };

  selectOptionsByIds = (ids?: number[]) => {
    const options = this.root.options.getADRTypes();
    const selectedOptions = options.filter(option => ids?.includes(option.value));

    this.selectedOptions = selectedOptions;
  };

  selectOption = (_option: OptionType | null, data: MultiSelectChangeData) => {
    this.selectedOptions = data.array;
  };
}

export { ADR };
