/* eslint-disable func-names */
export function insertIcons() {
  if (typeof window === 'undefined') return;
  const xhr = new XMLHttpRequest();

  const icons = `https://files.ati.dev/static/ui/assets/_sprite.svg`;
  xhr.open('GET', icons, true);
  xhr.send();

  let iconsData = '';
  let spriteContainer = '';
  let sprite = '';

  function setSprite() {
    spriteContainer.innerHTML = iconsData;

    sprite = spriteContainer.querySelector('svg');
    sprite.style.height = '0';
    sprite.style.width = '0';

    // eslint-disable-next-line no-console
    console.log('icons sprite inserted 🥲');
  }

  xhr.onload = function () {
    if (xhr.status !== 200) {
      console.error(`Icons loading error (code ${xhr.status}): ${xhr.statusText}`);
      return;
    }

    if (!(xhr.response.includes('<svg') && xhr.response.includes('<symbol'))) {
      console.error(`Icons loading error: Invalid icons sprite loaded`);
      return;
    }

    iconsData = xhr.response;

    spriteContainer = document.createElement('div');
    document.body.insertBefore(spriteContainer, document.body.firstChild);

    spriteContainer.id = 'icons-sprite';
    spriteContainer.style.height = '0';
    spriteContainer.style.width = '0';

    if (iconsData) {
      setSprite();
    }
  };
}
