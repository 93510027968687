export enum PayedSerivces {
  AllLoads = 1,
  ForwarderLicense = 19,
  UnlimititedSubscriptionNotifications = 346,
  Carrier = 348,
  AllInclusive = 403,
  WithoutAds = 563,
}

export enum UserRights {
  CommonSearchFilterAccess = '64',
}
