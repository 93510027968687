import { TFirmList } from 'store/Dictionaries/Dictionaries';

import axios from './axios';

export function getPrivateFirmsList() {
  return axios.get<TFirmList[]>('/gw/scrolls/v1/firms');
}

export function getFirmsCompletionList(keywords: string) {
  const data = {
    keywords,
    with_contacts: false,
    with_arrays: false,
    with_stars: false,
    offset: 0,
    limit: 10,
  };

  return axios.post('/webapi/extendedsearch/v2/firms', data);
}
