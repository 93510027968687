/* eslint-disable @next/next/no-before-interactive-script-outside-document */
// TODO: Сделать по нормальному. Если убрать beforeInteractive, не загружается React.
// https://github.com/vercel/next.js/issues/26343
import { configure } from 'mobx';
import { NextApiRequest } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React, { useEffect } from 'react';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { UserTheme } from 'components/ThemeSwitch/useTheme';
import { newsWidgetSource, tendersCardSource, truckmarketSource } from 'constants/pages';
import { isBotRegexp } from 'constants/regexps';
import { StoreProvider } from 'context/StoreContext';
import { TI18n, TSupportedLocales } from 'localization/types';
import { RootStore } from 'store/RootStore';
import { TBuildEnv } from 'types/global';
import { insertIcons } from 'utils/dev/insertIcons';
import { populateRelease } from 'utils/env';
import { renderSplashScreenIfRequired } from 'utils/renderSplashScreen';
import { interpolate } from 'utils/string/format';

import 'styles/variables.css';
import 'styles/globals.css';

configure({
  isolateGlobalState: true,
});

const MyApp = ({ Component, pageProps }: AppProps<TInitialData>) => {
  const { i18n, userTheme } = pageProps.initialData;

  const env = pageProps.initialData.host.split('.')[1];
  const store = new RootStore(i18n);

  store.ui.setUserTheme(userTheme);

  useEffect(() => {
    populateRelease();

    if (env === 'dev') {
      insertIcons();
    }
  }, [env]);

  useEffect(() => {
    store.app.initialize(pageProps.initialData);

    const languageHandler = (locale: TSupportedLocales) => {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      document.cookie = `uicult2=${locale}; expires=${date}`;
      document.location.reload();
    };

    window.RenderFooter?.({
      hideFlags: false,
      languageHandler,
      availableCultures: ['en', 'ru', 'kk', 'uz', 'hy', 'ky'],
    });

    window.addEventListener('hashchange', () => {
      store.app.fillFilterFromQuery();
    });
  });

  renderSplashScreenIfRequired();

  /**
   * viewport мета-тег нужен только в мобильных браузерах
   * убирая его на десктопе мы помогаем мобильным браузерам
   * отображать полную версию сайта по запросу пользователя через браузерную фичу
   * nextjs при этом не позволяет его убрать совсем, поэтому отдаём в content пустую строку
   */
  const viewportContent = pageProps.initialData.isMobile ? 'width=device-width, initial-scale=1' : '';

  return (
    <StoreProvider store={store}>
      <Head>
        <title>{i18n.app.title}</title>
        <meta content={i18n.app.seo.mainDescription} name="description" />
        <meta content={viewportContent} name="viewport" />

        <meta content={i18n.app.title} property="og:title" />
        <meta content="website" property="og:type" />
        <meta content={`https://loads.ati.${env}/`} property="og:url" />
        <meta content="https://files.ati.su/static/mp/assets/img/ati_logo_open_graph.png" property="og:image" />
        <meta content="400" property="og:image:width" />
        <meta content="300" property="og:image:height" />
        <meta content="LOADS.ATI.SU – Поиск грузов" property="og:site_name" />
        <meta content={i18n.app.seo.mainDescription} property="og:description" />

        <meta content="vBELTiX3pT94xvWei473g2nfgld14GFbI4g2nF5sIn4" name="google-site-verification"></meta>
        <meta content="l4TAfAdm34YriE8Y4Q12eovOv3DOgByqsQX21IUqo7c=" name="verify-v1"></meta>
        <meta content="b9264ebc77b2bd6c" name="yandex-verification"></meta>
        <meta content="#ffffff" name="theme-color" />
      </Head>

      <Script
        src={`https://files.ati.${env}/bundles/vendor/react/17.0.2/react.min.js`}
        strategy="beforeInteractive"
        type="text/javascript"
      />
      <Script
        src={`https://files.ati.${env}/bundles/vendor/react-dom/17.0.2/react-dom.min.js`}
        strategy="beforeInteractive"
        type="text/javascript"
      />
      {pageProps.initialData.isMobile && (
        <Script
          src={`//files.ati.${env}/static/splash-screens/renderSplashScreen.js`}
          strategy="beforeInteractive"
          type="text/javascript"
        />
      )}

      <Script src={interpolate(tendersCardSource.js, { env })} strategy="afterInteractive" />

      {pageProps.initialData.locale === 'ru' && (
        <Script
          id="tm-martket"
          src={interpolate(truckmarketSource.js, { env })}
          strategy="lazyOnload"
          onLoad={() => {
            if (typeof window !== 'undefined' && window.renderTmWidget && !store.profile.hasWithoutAdsLicence) {
              const truckmarketCss = document.createElement('link');
              truckmarketCss.rel = 'stylesheet';
              truckmarketCss.href = interpolate(truckmarketSource.css, { env });
              document.getElementById('tm-martket')?.insertAdjacentElement('beforebegin', truckmarketCss);
              window.renderTmWidget('truckmarket-widget');
            }
          }}
        />
      )}
      {pageProps.initialData.locale === 'ru' && (
        <Script
          id="news-widget"
          src={interpolate(newsWidgetSource.js, { env })}
          strategy="lazyOnload"
          onLoad={() => {
            if (typeof window !== 'undefined' && window.renderNewsWidget && !store.profile.hasWithoutAdsLicence) {
              window.renderNewsWidget('.news-widget');
              window.renderNewsWidget('.news-widget-aside');
            }
          }}
        />
      )}

      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </StoreProvider>
  );
};

export type TInitialData = {
  initialData: {
    locale: TSupportedLocales;
    host: string;
    atiCode: string | null;
    isMobile: boolean;
    i18n: TI18n;
    isBot: boolean;
    userTheme: UserTheme;
  };
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const initialRequest = appContext.ctx.req as NextApiRequest & {
    i18n: TI18n;
    buildEnv: TBuildEnv;
  };
  const locale = initialRequest.cookies.uicult2?.split('-')[0] || 'ru';

  appProps.pageProps.initialData = {
    locale,
    host: '',
    atiCode: null,
    isMobile: false,
    i18n: initialRequest.i18n,
    buildEnv: initialRequest.buildEnv,
  };

  appProps.pageProps.initialData.host = initialRequest.headers.host?.split('.').slice(-2).join('.');
  appProps.pageProps.initialData.atiCode = initialRequest.headers['x-ati-code'];
  appProps.pageProps.initialData.isMobile = /iPhone|iPad|Android/i.test(initialRequest.headers['user-agent'] || '');
  appProps.pageProps.initialData.isBot = isBotRegexp.test(initialRequest.headers['user-agent'] || '');
  appProps.pageProps.initialData.userTheme = initialRequest.cookies.ati_theme;

  return { ...appProps };
};

export default MyApp;
