export const paymentTypes = {
  withPrepayment: '2',
  withoutRate: '512',
  cash: '8',
  rateWithNDS: '1024',
  rateWithoutNDS: '2048',
  withRate: '1',
};

export const currenciesRateTypes = {
  total: 'total',
  perKm: 'per_km',
  perHour: 'per_hour',
} as const;

export const currenciesModifiers = {
  default: '1',
  thousands: '1000',
};
