import { TGeoSuggestionValues, geoSuggestionTypes } from 'constants/geo';
import { TUikitSupportedLocale } from 'localization/types';
import { GeoListType } from 'types/cargosApp/Geo';

import axios from '../axios';

type TSupportedGeoTypes = Extract<TGeoSuggestionValues, 0 | 1 | 2>;

export const geoTypesMap = {
  [geoSuggestionTypes.country]: 'countries',
  [geoSuggestionTypes.region]: 'regions',
  [geoSuggestionTypes.city]: 'cities',
};

function getGeosByIds(ids: string[], geoType: TSupportedGeoTypes) {
  return axios.post(`/gw/gis-dict/public/v1/geo/${geoTypesMap[geoType]}/by-ids`, { ids });
}

function getGeoCompletionList(prefix: string, locale: TUikitSupportedLocale = 'ru', geoTypes: number) {
  const localeMap = {
    ru: 'ru',
    en: 'eng',
  };

  return axios.post('/webapi/public/extendedsearch/v1/geo/', {
    prefix,
    geo_types: geoTypes,
    Language: localeMap[locale],
  });
}

function getPublicGeoLists() {
  return axios.get<GeoListType[]>('/webapi/public/firms/v1.0/lists/geo/global');
}

function getPrivateGeoLists() {
  return axios.get<GeoListType[]>('/webapi/firms/v1.0/lists/geo');
}

export { getGeoCompletionList, getPublicGeoLists, getPrivateGeoLists, getGeosByIds };
