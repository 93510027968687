import { makeAutoObservable, runInAction } from 'mobx';

import { TServerProp, TSiteFeature, getServerProperties, getSiteFeatures } from 'api/siteFeatures';
import logger from 'sentry.client.config';

export enum SiteFeaturesIDs {
  lots = 101008,
  newSaveFilterMethod = 106220,
  newFavoritesTab = 132500,
  doubleRateFilter = 77777,
  extendedADR = 3838,
}

export enum ServerPropsIDs {
  cargoSubscriptionsTrialDaysDuration = 'CargoSubscriptionsTrialDaysDuration',
}

const siteFeatures: SiteFeaturesIDs[] = [
  SiteFeaturesIDs.lots,
  SiteFeaturesIDs.newSaveFilterMethod,
  SiteFeaturesIDs.newFavoritesTab,
  SiteFeaturesIDs.doubleRateFilter,
  SiteFeaturesIDs.extendedADR,
];
const serverProps: ServerPropsIDs[] = [ServerPropsIDs.cargoSubscriptionsTrialDaysDuration];

export class SiteFeatures {
  isSiteFeatureEnabled: TSiteFeature = {};
  serverProps: TServerProp[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getServerProp = (serverPropId: ServerPropsIDs) => {
    return this.serverProps.find(({ Name }) => Name === serverPropId);
  };

  fetchSiteFeatures = async () => {
    try {
      if (!siteFeatures.length) return;

      const { data } = await getSiteFeatures(siteFeatures);

      runInAction(() => {
        this.isSiteFeatureEnabled = data;
      });
    } catch (e) {
      console.error(e);
    }
  };

  fetchServerProps = async () => {
    try {
      if (!serverProps.length) return;

      const { data } = await getServerProperties(serverProps);

      // сервис иногда возвращает что-то, что не является массивом, при этом ответ 200
      if (Array.isArray(data)) {
        runInAction(() => {
          this.serverProps = data;
        });
      } else {
        logger.log(`getServerProperties returns ${typeof data}: ${JSON.stringify(data)}`, 'info');
        logger.error(new Error(`getServerProperties returns ${typeof data}: ${JSON.stringify(data)}`));
      }
    } catch (e) {
      console.error(e);
    }
  };
}
