import { IFirmGroupLists, TFirmList } from '../Dictionaries';

const fillFavoriteList = (count: number, lists: TFirmList[][]) => {
  const favoriteList: TFirmList[] = [];

  for (let i = 0; i < lists.length && favoriteList.length < count; i++) {
    favoriteList.push(...lists[i].slice(0, count - favoriteList.length));
  }

  return favoriteList;
};

export const makeFirmFilters = (data: TFirmList[], strings: any): IFirmGroupLists => {
  const neutralLists = data.filter(item => item.type === 0);
  const whiteLists = data.filter(item => item.type === 1);
  const blackLists = data.filter(item => item.type === 2);
  const favInclusiveLists = [whiteLists, neutralLists, blackLists];
  const favExclusiveLists = [blackLists, neutralLists, whiteLists];

  const firmFilters = {
    favInclusiveLists: fillFavoriteList(4, favInclusiveLists),
    favExclusiveLists: fillFavoriteList(4, favExclusiveLists),
    neutralLists: {
      name: strings.neutralLists,
      lists: neutralLists,
    },
    whiteLists: {
      name: strings.positiveLists,
      lists: whiteLists,
    },
    blackLists: {
      name: strings.negativeLists,
      lists: blackLists,
    },
    all: {
      name: strings.allLists,
      lists: data,
    },
    totalLists: data.length,
  };

  return firmFilters;
};
