import { en } from './en';
import { hy } from './hy';
import { kk } from './kk';
import { ru } from './ru';
import { uz } from './uz';

export type LocaleType = 'ru' | 'en';

const localization = {
  ru,
  en,
  kk,
  uz,
  ky: ru,
  hy,
};

export { localization };
