import axios from './axios';

function getFiltersTabsData() {
  return axios.get('/gw/filters-bff-api/web/v2/cargos/filters/for-tabs');
}

function getTabsData() {
  return axios.get('/api/userfilters/tabs');
}

function deleteSavedFilterRequest(filterId: string) {
  return axios.delete(`/webapi/v1.0/loads/filters/${filterId}`);
}

function deleteLastSearchRequest(filterId: string) {
  return axios.delete(`/webapi/v1.0/loads/search/last-searches/${filterId}`);
}

export { getTabsData, getFiltersTabsData, deleteSavedFilterRequest, deleteLastSearchRequest };
