export const pascalToSnake = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .map(str => str.toLowerCase())
    .join('_');

export const camelToKebab = (str: string) => pascalToSnake(str).replace('_', '-');

export const transformObjKeysRecursive = (obj: { [key: string]: any }, func: (str: string) => string) => {
  if (!(obj && (Array.isArray(obj) || typeof obj === 'object'))) {
    return obj;
  }

  const newObj: { [key: string]: any } | any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    if (obj[key] == null) {
      newObj[func(key)] = obj[key];
    } else {
      if (Array.isArray(obj[key])) {
        newObj[func(key)] = obj[key].map((value: { [key: string]: any }) => transformObjKeysRecursive(value, func));
      } else if (typeof obj[key] === 'object') {
        newObj[func(key)] = transformObjKeysRecursive(obj[key], func);
      } else {
        newObj[func(key)] = obj[key];
      }
    }
  }

  return newObj;
};

const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);
const uncapitalize = (str: string) => str.substr(0, 1).toLowerCase() + str.substr(1);

const snakeToPascal = (str: string) => str.split('_').map(capitalize).join('');

export const snakeToCamel = (str: string) => uncapitalize(snakeToPascal(str));

/* @ts-ignore */
export const snakeToCamelObj = <T>(obj: T): CamelCasedPropertiesDeep<T> => transformObjKeysRecursive(obj, snakeToCamel);

export const getEnv = () => {
  if (typeof window !== 'undefined') {
    const env = window.location?.hostname?.split('.').slice(-1)?.[0];
    return env || 'su';
  }

  return 'su';
};
