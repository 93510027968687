import type { CamelCasedPropertiesDeep, PascalCasedPropertiesDeep, SnakeCasedPropertiesDeep } from 'type-fest';

import { capitalize, pascalToSnake, snakeToCamel } from './string/format';

// TODO:
// После обновления ts он прозрел и понял, что это кал.
// Нужны более внятные типы. Пока натыкал /* @ts-ignore */

const cleanEmpty = <T>(obj: T): any => {
  if (Array.isArray(obj)) {
    const candidate = obj.map(v => (v && typeof v === 'object' ? cleanEmpty(v) : v)).filter(v => !(v == null));

    return candidate.length > 0 ? candidate : undefined;
  } else {
    /* @ts-ignore */
    const candidate = Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
      /* @ts-ignore */
      .reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});

    return isEmpty(candidate) ? undefined : candidate;
  }
};

export const transformObjKeysRecursive = (obj: { [key: string]: any }, func: (str: string) => string) => {
  if (!(obj && (Array.isArray(obj) || typeof obj === 'object'))) {
    return obj;
  }

  const newObj: { [key: string]: any } | any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    if (obj[key] == null) {
      newObj[func(key)] = obj[key];
    } else {
      if (Array.isArray(obj[key])) {
        newObj[func(key)] = obj[key].map((value: { [key: string]: any }) => transformObjKeysRecursive(value, func));
      } else if (typeof obj[key] === 'object') {
        newObj[func(key)] = transformObjKeysRecursive(obj[key], func);
      } else {
        newObj[func(key)] = obj[key];
      }
    }
  }

  return newObj;
};

/* @ts-ignore */
export const snakeToCamelObj = <T>(obj: T): CamelCasedPropertiesDeep<T> => transformObjKeysRecursive(obj, snakeToCamel);

export const camelToSnakeObj = <T>(obj: T): SnakeCasedPropertiesDeep<T> =>
  /* @ts-ignore */
  transformObjKeysRecursive(obj, pascalToSnake);

export const isEmpty = (obj: Object) => Object.keys(obj).length === 0;

export const snakeToPascal = (string: string) => string.split('_').map(capitalize).join('');

export const snakeToPascalObj = <T>(obj: T): PascalCasedPropertiesDeep<T> =>
  /* @ts-ignore */
  transformObjKeysRecursive(obj, snakeToPascal);

export { cleanEmpty };
